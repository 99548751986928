@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap');
/** “Inter” is for headlines, buttons, “Roboto” is for body, inputs, and captions */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

.mentem-action-text {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.mentem-action-delete {
  color: #ba61ff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}

.mentem-action-cancel {
  color: #6b69c1;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}

.mentem-action-or {
  color: #6b69c1;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.mentem-action-clear {
  color: #1769ff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
}

.mentem-button {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 14px;
}

.mentem-button-large-primary {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #bd69fe;
  background: #bd69fe;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.mentem-button-large-primary:hover {
  border: 1px solid #f4f4f4;
  background: #fff;
  color: #000;
  box-shadow: 0px 16px 24px 0px rgba(13, 13, 13, 0.2);
}

.mentem-button-large-secondary {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #f4f4f4;
  background: #fff;
  box-shadow: 0px 16px 24px 0px rgba(13, 13, 13, 0.2);
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.mentem-button-large-secondary:hover {
  background: #bd69fe;
  color: #fff;
  border: 1px solid #bd69fe;
  box-shadow: 0px 0px 0px 0px;
}

.mentem-button-sidebar {
  font-family: 'Inter';
  font-weight: bold;
  font-size: 16px;
}

.mentem-dropdown-button {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
}

.mentem-headline {
  font-family: 'Inter';
  font-size: 33px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  color: #bd69fe;
}

.mentem-headline-small {
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #bd69fe;
}

.mentem-menu-button {
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 32px;
  border: 0.3px solid #d5d5db;
  box-shadow: 0px 16px 24px 0px rgba(48, 49, 51, 0.1);
}

.mentem-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.mentem-scrollbar::-webkit-scrollbar-thumb {
  background-color: #e1e1e5;
}

.mentem-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.mentem-scrollbar-2::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.mentem-scrollbar-2::-webkit-scrollbar-thumb {
  background-color: #b5b5be;
  border-radius: 20px;
}

.mentem-scrollbar-2::-webkit-scrollbar-track {
  background-color: transparent;
}

.mentem-widget-title {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
}

.mentem-widget-subtitle {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

.authoring-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.authoring-iframe {
  height: 70vh;
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.authoring-questions-container {
  border: 3px solid #92929d;
  background-color: white;
  width: 100%;
  padding: 2px;
}

.authoring-questions-box {
  width: 100%;
  max-height: 333px;
  overflow: auto;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
/* End of snackbar */

/* Uplaod / Manage image */
/* .hasImage:hover section {
  background-color: rgba(217, 217, 217, 0.8);
} */

/* .hasImage button {
  visibility: hidden;
} */

.hasImage:hover button {
  visibility: visible;
}

.hasImage:hover button:hover {
  background: rgba(217, 217, 217, 0.8);
}

.hasImage:hover .image-desc {
  visibility: visible;
}

.hasImage:hover .image-desc-bg {
  visibility: visible;
}

.imagePreview section {
  background-color: transparent;
}
.imagePreview button {
  background: white;
}

.image-desc {
  visibility: hidden;
}

.image-desc-bg {
  visibility: hidden;
  background-color: rgba(217, 217, 217, 0.8);
  border-radius: 4px;
}

#overlay p,
i {
  opacity: 0;
}

#overlay.draggedover {
  background-color: rgba(255, 255, 255, 0.7);
}
#overlay.draggedover p,
#overlay.draggedover i {
  opacity: 1;
}
/* End of Uplaod image */

.tag-folder {
  background-image: url('images/folder.svg');
  width: 167px;
  height: 143px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
  word-wrap: break-word;
  cursor: pointer;
}

/* Override carousel css */
.carousel .carousel-status {
  position: absolute;
  top: 84% !important;
  right: 50% !important;
  font-size: 14px !important;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.9) !important;
  color: #000 !important;
  transform: translateX(50%) !important;
  font-weight: bold;
  background-color: #fff;
  padding-left: 6px !important;
  padding-right: 6px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  border-radius: 3px;
  z-index: 1;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
  color: white !important;
  background-color: rgba(69, 69, 74, 0.9) !important;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel .control-dots {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  background: #3c415d;
}

.carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 1 !important;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0) !important;
  background: #fff;
  border-radius: 50%;
  width: 6px !important;
  height: 6px !important;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px !important;
}
/* End of Override carousel css */

/* checkbox */
input[type='checkbox'] {
  accent-color: white;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

input[type='checkbox']:disabled {
  cursor: default;
}

input[type='checkbox']::before {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('images/checkbox.svg');
}

input[type='checkbox']:checked::before {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('images/checkbox-tick.svg');
}

input[type='checkbox']:disabled::before {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('images/checkbox-disabled.svg');
}

.input-checkbox-deselected {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('images/checkbox-deselected.svg');
}

.checkbox-label {
  color: #121419;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.checkbox-label:hover {
  opacity: 0.8;
}

.checkbox-label-normal {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
}

.checkbox-label-normal:hover {
  opacity: 0.8;
}

/* End of checkbox */

/* radio button */

input[type='radio'] {
  accent-color: white;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

input[type='radio']::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('images/radio.svg');
}

input[type='radio']:checked::before {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('images/radio-selected.svg');
}

/* Normal / Black style */
input[type='radio'].radio-small::before {
  width: 16px;
  height: 16px;
  background-image: url('images/radio.svg');
}

input[type='radio'].radio-small:checked::before {
  background-image: url('images/radio-selected.svg');
}

/* Primary / Purple style */
input[type='radio'].radio-primary::before {
  width: 16px;
  height: 16px;
  background-image: url('images/radio-primary.svg');
}

input[type='radio'].radio-primary:checked::before {
  background-color: white;
  background-image: url('images/radio-primary-selected.svg');
}

/* End of radio button */

/** Toastify */
.Toastify__toast--error {
  border: 1px solid #d5d5db;
  border-radius: 30px !important;
  background: #fff !important;
}
.Toastify__toast--error::after {
  content: url('images/toast-close.svg');
  position: absolute;
  right: 20px;
  top: 4px;
}
.Toastify__toast--error::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success {
  border: 1px solid #d5d5db !important;
  border-radius: 30px !important;
  background: #fff !important;
}
.Toastify__toast--success::before {
  /* content: url('images/toast-success.svg'); */
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success::after {
  content: url('images/toast-close.svg');
  position: absolute;
  right: 20px;
  top: 4px;
}
.Toastify__toast--warning {
  border: 1px solid #d5d5db !important;
  border-radius: 30px !important;
  background: #fff !important;
}
.Toastify__toast--warning::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--warning::after {
  content: url('images/toast-close.svg');
  position: absolute;
  right: 20px;
  top: 4px;
}
.Toastify__toast-body {
  content: '';
  color: #000;
  font-size: 16px;
  padding-left: 10px !important;
  padding-right: 16px !important;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 700;
  margin-left: 4px !important;
}
.Toastify__toast > button > svg {
  display: none;
}
.Toastify__toast {
  min-height: 52px !important;
}
/** End of Toastify */

.mentem-text-primary {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.mentem-chip-item {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.mentem-input-text {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #3c415d;
}

.mentem-table {
  width: 100%;
}

.mentem-table-highlight tr:hover td {
  background-color: #f4e7ff !important;
  /* border: 0 !important; */
}
.mentem-table-highlight tr:hover td:nth-child(2n) {
  background-color: #eaddf6 !important;
}

.mentem-table-header-1 {
  background: #f3f3f5;
  height: 36px;
  padding-left: 6px;
  padding-right: 6px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  /* border-top: 1px solid #fff; */
  border-bottom: 1px solid #fff;
}

.mentem-table-header-2 {
  background: #fff;
  height: 36px;
  padding-left: 6px;
  padding-right: 6px;
  border-left: 1px solid #f3f3f5;
  border-right: 1px solid #f3f3f5;
  /* border-top: 1px solid #f3f3f5; */
  border-bottom: 1px solid #f3f3f5;
}

.mentem-table-cell-1 {
  background: #f3f3f5;
  height: 52px;
  padding-left: 6px;
  padding-right: 6px;
  border: 1px solid #fff;
}

.mentem-table-cell-2 {
  background: #fff;
  height: 52px;
  padding-left: 6px;
  padding-right: 6px;
  border: 1px solid #f3f3f5;
}

.mentem-table > tbody > tr > td {
  padding-left: 6px;
  padding-right: 6px;
}

.mentem-table > tbody > tr > td > div {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
}

.mentem-table > thead > tr > th {
  white-space: nowrap;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
}

.mentem-link-underline {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #0061ff;
  text-decoration: underline;
  cursor: pointer;
}

.mentem-link-underline:hover {
  opacity: 0.8;
}

.mentem-link {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #199afb;
}

.mentem-link:hover {
  color: #105efb;
}

.mentem-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  border-radius: 6px;
  border: 0.3px solid #6b69c1;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(48, 49, 51, 0.05);
  color: #000;
}

.mentem-tooltip-checker {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  border-radius: 6px;
  border: 0.3px solid #3c415d;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(48, 49, 51, 0.05);
  color: #000;
}

.mentem-tooltip-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  border-radius: 6px;
  border: 0.3px solid #d5d5db;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(48, 49, 51, 0.05);
  color: #000;
}

.mentem-tooltip-large {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  border-radius: 6px;
  border: 0.3px solid #6b69c1;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(48, 49, 51, 0.05);
  color: #000;
}

.mentem-title-column {
  height: 55px;
  width: 217px;
}

.mentem-title-box {
  /*height: 52px;*/
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  align-items: center;
}

.mentem-comment-box {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #121419;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 3px;
}

/* start of file catalog stuff */

.file-catalogue-tab {
  cursor: pointer;
  margin-top: 12px;
  padding: 7px 19px 4px 6px;
  border-right: 0.3px solid white;
  border-bottom: 0.3px solid white;
  border-left: 0.3px solid white;
}
.file-catalogue-tab > p {
  padding: 0px 10px;
  line-height: 17px;
  font-size: 14px;
  font-weight: 700;
}
.file-catalogue-tab:hover,
.file-catalogue-tab.active {
  border-radius: 0px 60px 60px 0px;
  border-right: 0.3px solid #f0f0f0;
  border-bottom: 0.3px solid #f0f0f0;
  border-left: 0.3px solid #f0f0f0;
  box-shadow: 0px 16px 24px 0px rgba(13, 13, 13, 0.2);
  color: #bd69fe;
  background: white;
}

.file-catalogue-tab:hover > svg,
.file-catalogue-tab.active > svg {
  fill: #bd69fe;
}
.file-catalogue-tab > svg {
  fill: black;
}

/* end of file catalog stuff */

/* start of quiz review stuff */

.quiz-status-draft {
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}

.quiz-status-reviewed {
  color: #bd69fe;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}

.quiz-status-sent {
  color: #0061ff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}

.quiz-status-approved {
  color: #20744a;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}

.quiz-status-deleted {
  color: #eb2d44;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}

.quiz-name {
  width: 100%;
  min-height: 42px;
  padding: 8px;
  border: 1px solid transparent;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.quiz-name-input {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #b5b5be;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  outline-style: none;
  overflow: hidden;
}

.quiz-name-input::placeholder {
  color: #92929d;
}

.quiz-upload-placeholder {
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.quiz-description {
  width: 100%;
  min-height: 36px;
  padding: 8px;
  border: 1px solid transparent;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.quiz-description-input {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #b5b5be;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  outline-style: none;
  overflow: hidden;
}

.quiz-description-input::placeholder {
  color: #92929d;
}

.quiz-question {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.6px;
}

.quiz-answer {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
}

.quiz-separator {
  width: 100%;
  height: 1px;
  background-color: #bcbcbc;
}

.quiz-email-message {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
}

.quiz-email-input {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  outline-style: none;
  height: 100%;
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  border: 1px solid #d5d5db;
  border-radius: 3px;
  resize: none;
}

.quiz-editable-question {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.6px;
  text-decoration-line: underline;
  resize: none;
  width: 100%;
  outline-style: none;
}

.quiz-editable-question::placeholder {
  color: #92929d;
}

.quiz-editable-text {
  color: #000;
}

.quiz-editable-empty {
  color: #92929d;
}

.quiz-editable-answer {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  text-decoration-line: underline;
  resize: none;
  width: 100%;
  outline-style: none;
}

.quiz-editable-answer-feedback {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  text-decoration-line: underline;
  resize: none;
  outline-style: none;
  width: fit-content;
}

.quiz-editable-answer::placeholder {
  color: #92929d;
}

.quiz-email-input::placeholder {
  color: #92929d;
}

.quiz-text-approved {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #20744a;
}

.quiz-text-rejected {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #bd69fe;
}

.quiz-text-comment {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #32374e;
}

.quiz-comment-container {
  background: #fdff85;
  padding: 10px;
}

.quiz-review-details-status {
  color: #bd69fe;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.quiz-learning-outcome {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.quiz-topic-link {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
}

.quiz-topic-link > a {
  color: #0061ff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.quiz-editable-learning-outcome {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  resize: none;
  width: 100%;
  outline-style: none;
}

.quiz-editable-learning-outcome::placeholder {
  color: #92929d;
}

.quiz-approving-confirmation {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.add-media-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 25px;
  border: 3px solid #d5d5db;
  border-radius: 6px;
}

.add-media-container-loading {
  border: 3px solid #bd69fe;
}

.add-media-drop {
  color: #b5b5be;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.add-media-loading {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  width: 130px;
}

.add-media-preview {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  aspect-ratio: object-fit;
}

.quiz-create-container {
  display: flex;
  width: 560px;
  height: 270px;
  padding: 72px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid rgba(213, 213, 219, 0.6);
  background: #fff;
  box-shadow: 0px 16px 24px 0px rgba(48, 49, 51, 0.1);
}

.quiz-or-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  color: #121419;
}

.quiz-restore-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  color: #bd69fe;
  cursor: pointer;
}

.quiz-restore-text:hover {
  opacity: 0.8;
}

.quiz-create-name-desc-container {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #d5d5db;
  padding: 1px;
}

.quiz-create-name-input {
  width: 100%;
  padding: 8px;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  outline-style: none;
  overflow: hidden;
}

.quiz-create-name-input::placeholder {
  color: #92929d;
}

.quiz-create-description-input {
  width: 100%;
  padding: 8px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  outline-style: none;
  overflow: hidden;
}

.quiz-create-description-input::placeholder {
  color: #92929d;
}

.quiz-create-more {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
  color: #000000;
}

.quiz-create-more:hover {
  opacity: 0.8;
}

/* start of svg button */

.button-svg {
  fill: #b5b5be;
  cursor: pointer;
  outline: none;
}

.button-svg:hover {
  fill: #000;
}

.button-svg-fill-black {
  fill: #a3a3a9;
  cursor: pointer;
  outline: none;
}

.button-svg-fill-black:hover {
  fill: #000;
}

.button-svg-fill-black-2 {
  fill: #51597b;
  cursor: pointer;
  outline: none;
}

.button-svg-fill-black-2:hover {
  fill: #000;
}

.button-svg-fill-white {
  fill: #ffffff;
  cursor: pointer;
  outline: none;
}

.button-svg-fill-white:hover {
  fill: #a3a3a9;
}

.button-svg-stroke-black {
  stroke: #a3a3a9;
  cursor: pointer;
}

.button-svg-stroke-black:hover {
  stroke: #000;
}

.button-svg-stroke-white {
  stroke: #ffffff;
  cursor: pointer;
}

.button-svg-stroke-white:hover {
  stroke: #a3a3a9;
}

/* end of svg button */

.quiz-no-quiz-found {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
}

.quiz-review-details-reviewer {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  color: #bd69fe;
}

.quiz-create-new-container {
  background: #fff;
  box-shadow: 0px 2159px 604px 0px rgba(0, 0, 0, 0),
    0px 1382px 553px 0px rgba(0, 0, 0, 0.01),
    0px 777px 466px 0px rgba(0, 0, 0, 0.05),
    0px 345px 345px 0px rgba(0, 0, 0, 0.09),
    0px 86px 190px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 12px 120px 24px 120px;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

/* end of quiz review stuff */

/* start of architectures */

.arch-subheading {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.arch-topic-list-item-link {
  color: #0061ff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  background: transparent;
}

.arch-topic-list-item-title {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  background: transparent;
  outline: none;
  resize: none;
}

.arch-read-only {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: transparent;
  outline: none;
  resize: none;
}

.arch-topic-list-item-highlight {
  background-color: #f4e7ff !important;
}

.arch-module-list-item-read-only {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  background: transparent;
  outline: none;
  resize: none;
}

.arch-module-list-item-add-input {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  background: transparent;
  outline: none;
  text-decoration: underline;
}

.arch-read-only-small {
  color: #0d0d0d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  background: transparent;
  outline: none;
  resize: none;
}

.arch-editor-list {
  width: 100%;
  color: #121419;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.arch-editor-list > li {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 36px;
}

.arch-editor-list > li:hover {
  background-color: #f3f3f5;
}

.arch-editor-list > li.arch-module-list-item:hover {
  background-color: #e1e1e5;
}

.arch-editor-list > li.arch-header-list-item:hover {
  background-color: #828292;
}

.arch-editor-list > li > div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.arch-editor-list-title {
  color: #fff;
  background: #696974;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.arch-add-client-input-text {
  border-radius: 3px;
  border: 0.6px solid #32374e;
  color: #92929d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  height: 36px;
}

.arch-client-input-text {
  border-radius: 3px;
  border: 0.6px solid #32374e;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  height: 36px;
}

.arch-add-client-input-text:focus {
  outline: none;
  text-decoration-line: underline;
}

.arch-client-input-text:focus {
  outline: none;
  text-decoration-line: underline;
}

.arch-add-client-input-text::placeholder {
  color: #92929d;
}

.arch-client-input-text::placeholder {
  color: #92929d;
}

/* .arch-program-list-item {
  background: #f3f3f5;
} */

.arch-module-list-item {
  border: 1px solid #d5d5db;
  /* border-bottom: none; */
  background: #f3f3f5;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-left: 30px;
  padding-right: 41px !important;
  padding-top: 6px;
  padding-bottom: 6px;
}

.arch-assessment-header {
  border: 1px solid #d5d5db;
  background: #696974;
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-left: 30px;
  padding-right: 41px !important;
  padding-top: 6px;
  padding-bottom: 6px;
}

.arch-duplicate-text {
  color: #6b69c1;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.arch-action-button {
  width: 18px;
  min-width: 18px;
  max-width: 18px;
  cursor: pointer;
}

.arch-add-input {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  outline: none;
  background: transparent;
  color: #696974;
  text-decoration: underline;
  resize: none;
}

.arch-add-input-small {
  font-family: Roboto;
  font-size: 11px;
  font-weight: 600;
  font-style: italic;
  line-height: 16px;
  outline: none;
  background: transparent;
  color: #696974;
  text-decoration: underline;

  resize: none;
}

.arch-add-input::placeholder {
  color: #696974;
}

.arch-add-module-input {
  color: #fff;
}

.arch-add-module-input::placeholder {
  color: #fff;
}

.arch-topic-last-updated-at {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 600;
  line-height: 12.89px;
  text-align: left;
  color: #bd69fe;
}

/* end of architectures */

:root {
  --sidebar-max-width: 180px;
  --sidebar-min-width: 110px;
  --sidebar-transition: 0.4s ease-in-out;
  --sidebar-transition-max: 0.3s ease-out;
}

.sidebar-gradient-bg {
  background: var(
    --Linear-gradient,
    linear-gradient(
      176deg,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0.06) 93.15%
    ),
    linear-gradient(
      4deg,
      rgba(189, 105, 254, 0.1) 0%,
      rgba(189, 105, 254, 0.01) 100%
    ),
    linear-gradient(
      172deg,
      rgba(255, 255, 255, 0.2) 44.2%,
      rgba(193, 114, 254, 0.2) 72.88%,
      rgba(255, 255, 255, 0.2) 99.06%
    ),
    linear-gradient(
      359deg,
      rgba(255, 255, 255, 0.7) 0.05%,
      rgba(193, 114, 254, 0.7) 31.49%,
      rgba(193, 114, 254, 0.7) 59.88%,
      rgba(255, 255, 255, 0.7) 99.93%
    )
  );
  background-blend-mode: normal, normal, normal, multiply;
}

.sidebar-container {
  display: block;
  width: 100%;
  height: 100%;
}

.sidebar-menu {
  display: block;
  position: fixed;
  min-width: var(--sidebar-max-width);
  height: 100%;
  -webkit-transition: var(--sidebar-transition-max);
  -moz-transition: var(--sidebar-transition-max);
  -o-transition: var(--sidebar-transition-max);
  transition: var(--sidebar-transition-max);
}

.sidebar-content {
  display: block;
  position: absolute;
  width: calc(100% - var(--sidebar-max-width));
  height: 100%;
  left: var(--sidebar-max-width);
  overflow: auto;
  -webkit-transition: var(--sidebar-transition);
  -moz-transition: var(--sidebar-transition);
  -o-transition: var(--sidebar-transition);
  transition: var(--sidebar-transition);
}

.sidebar-menu-minimized {
  display: block;
  position: fixed;
  min-width: var(--sidebar-min-width);
  height: 100%;
  -webkit-transition: var(--sidebar-transition);
  -moz-transition: var(--sidebar-transition);
  -o-transition: var(--sidebar-transition);
  transition: var(--sidebar-transition);
}

.sidebar-content-minimized {
  display: block;
  position: absolute;
  width: calc(100% - var(--sidebar-min-width));
  height: 100%;
  left: var(--sidebar-min-width);
  overflow: auto;
  -webkit-transition: var(--sidebar-transition);
  -moz-transition: var(--sidebar-transition);
  -o-transition: var(--sidebar-transition);
  transition: var(--sidebar-transition);
}

.sidebar-button-left {
  position: fixed;
  top: 60px;
  left: calc(var(--sidebar-max-width) - 20px);
  transform: translateY(-50%);
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-transition: var(--sidebar-transition);
  -moz-transition: var(--sidebar-transition);
  -o-transition: var(--sidebar-transition);
  transition: var(--sidebar-transition);
}

.sidebar-button-right {
  position: fixed;
  top: 60px;
  left: calc(var(--sidebar-min-width));
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-transition: var(--sidebar-transition);
  -moz-transition: var(--sidebar-transition);
  -o-transition: var(--sidebar-transition);
  transition: var(--sidebar-transition);
}

.sidebar-menu-item-minimized {
  width: 190px;
  height: 60px;
  margin: 5px 0px 5px -100px;
  padding-left: 90px;
}
.sidebar-menu-item {
  width: 160px;
  height: 60px;
  margin: 5px 0px 5px -30px;
  padding-left: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.sidebar-menu-item:hover,
.sidebar-menu-selected-bg {
  background-color: white;
  border-radius: 0 35px 35px 0;
  box-shadow: 0px 16px 24px 0px rgba(13, 13, 13, 0.2);
}

.sidebar-menu-item-minimized:hover,
.sidebar-menu-selected-bg-min {
  background-color: white;
  border-radius: 0 35px 35px 0;
  box-shadow: 0px 16px 24px 0px rgba(13, 13, 13, 0.2);
}

.sidebar-menu-scrollbar {
  overflow: hidden;
  margin-right: 8px;
  height: 60vh;
}

/* Show scrollbar on hover */
.sidebar-menu-scrollbar:hover {
  overflow: auto;
}

/* Hide the default scrollbar */
.sidebar-menu-scrollbar::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.sidebar-menu-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.sidebar-menu-scrollbar::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 6px;
}
